body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f0f5 !important;
  /* padding: 20px 20px; */
}

code {
  font-family: "Mulish", sans-serif;
}
