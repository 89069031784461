.reset-password-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;
  .field-control {
    width: 100%;
    margin-bottom: 1rem;
  }
}
