// .loader-container {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: rgba(0, 0, 0, 0.5);
// }

// .loader {
//   border: 5px solid #f3f3f3;
//   border-top: 5px solid #3498db;
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   animation: spin 2s linear infinite;
// }

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
.loaderContainer {
  background: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 999;
  width: 100%;
  height: 100vh;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.loaderPopContainer {
  display: "flex";
  text-align: center;
  justify-content: center;
  padding: 20px;
}