.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;
  background: #ffffff;

  .field-control {
    width: 100%;
    margin-bottom: 1rem;
  }
}



.login-sub-head {
  font-weight: 400;
  margin-bottom: 30px;
  color: #c7b4a9;
  font-size: 14px;
  width: 335px;
}