.role-wrapper {
  .detail-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    button {
      max-width: 150px;
      width: 100;
    }
  }

  .role-divider {
    margin: 20px 0px;
  }

  .input-field {
    background-color: #f5f5f5;
    // border-radius: 40px;
    cursor: pointer;
    color: #bfbfbf;

  }

  .role-dis {
    display: flex;
    text-align: center;
    align-items: center;

    p {
      padding: 5px;
      max-width: 230px;
      font-size: 11px;
    }
  }

  .button-box {
    display: "flex";
    flex-direction: "row";
    justify-content: "space-between";
    text-align: center;
    gap: 20px;
    height: 40px;
    margin-top: 30px;

    button {
      margin-right: 10px;
    }
  }

  .span-button {
    font-size: 0.775rem !important;
  }
}