.role-wrapper {
  .detail-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px,
  }

  .role-divider {
    margin: 20px 0px;
  }

  .title-pad {
    margin-bottom: 20px
  }

  .input-field {
    background-color: #f5f5f5;
    // border-radius: 40px;
    cursor: pointer;
    color: #bfbfbf;

  }



  .button-container {
    button {
      margin-right: 10px;
      width: auto;
      padding: 5px 10px;
      margin: 10px;
    }
  }
}