.pop-up-wrapper {

    text-align: center;

    .MuiDialog-paper {
        border-radius: 10px;
        width: auto;
        min-width: 400px;
        padding: 0px 30px;
    }

    .pop-up-title {
        padding: 20px 0px;
        font-size: 22px;
        color: #000;
    }

    .pop-up-msg {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6)
    }

    .pop-up-btn {
        justify-content: center;
        display: flex;
        width: 100%;
        margin-bottom: 20px;

        .btn-width {
            max-width: 150px;
        }

    }

}