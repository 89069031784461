.detail-wrapper {
    .detail-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      
        button{
            max-width: 150px;
            width: 100;
        }
    }

    .MuiTypography-root {
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        color: #000;
        margin-bottom: 0;
    }

    
    .reset {
        margin-top: 10px;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #8a8a8a;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }

    .reset {
        :last-child {
            margin-left: 5px;
            margin-top: 5px;
            color: #199ab0;

            &:hover {
                cursor: pointer;
            }
        }


    }

}

.pop-up-wapper {
    .pop-title {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 15px;
            color: #8a8a8a;
            margin-left: 5px;
        }
    }
}

.pass-form-wrapper {
    margin-top: 10px;
}

.button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    :last-child {
        margin-left: 10px;
    }
}

.edit-profile-wrapper {
    .upload-image {
        img {
            color: #199ab0;
            max-width: 150px;
        }
    }
    label{
        max-width: 200px;
        overflow: hidden;
    }

    .pop-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
            font-size: 15px;
            color: #8a8a8a;
            margin-left: 5px;
            max-width: 200px;
            width: 100%;
        }
    }
}