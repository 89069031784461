.verification-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: #ffffff;
  height: 100vh;
  .field-control {
    width: 100%;
    // margin-bottom: 1rem;
  }
}
.sub-head{
    font-weight: 400;
      margin-bottom: 20px;
      color: #c7b4a9;
      font-size: 14px;
      width: 100%;
}
.action-btn {
  background: #199ab0;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 15px 0px;

  &:hover {
    background: #199ab0;
    color: #ffffff;
  }
}
