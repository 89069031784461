.inputField {
    margin: 2px 0px !important;
    // border-radius: 10px;
}

.input-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #272727;
    padding-left: 5px;
    margin-bottom: 4px;
}