.action-btn,
.link-btn {
    background: #199ab0;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    width: 100%;
    padding: 15px 0px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        background: #199ab0;
        color: #ffffff;
    }

    &:disabled {
        background: rgba(0, 0, 0, 0.12);
        color: #767676;
    }
}

.link-btn {
    background: transparent;
    color: #199ab0;
    cursor: pointer;
    border: none;

    &:hover {
        background: rgba(0, 0, 0, 0.12);
        color: #000000;
    }
}

.title {
    font-size: 19px;
    color: #000;
    // font-family: Mulish, sans-serif, Helvetica Neue, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    align-items: center;
    display: flex;
}

.sub-title {
    color: #000;
    font-weight: 700;
    font-size: 16px;
}

.input-label-title {
    color: #272727;
    padding: 0px !important;
    margin-bottom: 5px;
}

.cancel-btn {
    border-color:  #199ab0;
    background: #ffffff;
    color: #1ca2b7;
    border-radius: 10px;
    width: 100%;
    padding: 15px 0px;
    font-size: 16px;
    cursor: pointer;

    // &:hover {
    //     background: #199ab0;
    //     color: #ffffff;
    // }

    &:disabled {
        background: rgba(0, 0, 0, 0.12);
        color: #767676;
      
    }
}

.btn-small{
    max-width: 150px;
    width: 100%;
}