.center-container {
  text-align: center;
}

.qr-code-container {
  text-align: center;
}

.google-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;

  .google-auth-radio-group {
    margin-bottom: 1.5rem;
    flex-direction: row;

    .google-auth-radio {
      flex: 1;
      justify-content: space-between;
    }
  }

  .logo-line {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 2rem;

    .left-line {
      height: 2px;
      flex: 1;
      background: #ddd;
    }

    .right-line {
      height: 2px;
      flex: 1;
      background: #ddd;
    }
  }

  .device-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    img {
      width: 45px;
      cursor: pointer;
    }
  }
}

.scanner-wrapper {
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  padding: 100px 0px;

  .scanner-container {
    max-width: 550px;
    width: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 25px 0px;

    .verify-pass {
      max-width: 430px;
      width: 100%;
      margin: auto;
      // display: flex;
      // justify-content: center;
      text-align: center;
      align-items: center;
    }

  }

  .logo {
    max-width: 200px;
    width: 100%;
    margin-bottom: 3rem
  }

  h4 {
    font-weight: 300;
    color: #222;
    font-size: 2.2rem;
    margin: 0px;
  }

  p,
  span {
    margin-top: 10px;
    margin-bottom: 1rem;
    font-size: 15px;
    color: #222;
    font-weight: 300;
    line-height: 1.5;
  }

  .scanner-dis {
    display: flex;
    align-items: center;

    span,
    img {
      margin-right: 10px;
    }

    img {
      width: 25px;
    }
  }

  .scanner-box {
    // width: 100%;
    text-align: center;
    margin: 20px 10px;
    display: flex;
    width: 100%;
    max-width: 430px;

    align-items: center;
    text-align: center;
    padding: 2rem;
    border-radius: 12px;
    background: #baecf4;

    p {
      color: #000;
      font-size: 15px;
      max-width: 280px;
      margin-right: 10px;
    }

  }

  .button-wrapper {
    max-width: 400px;
    width: 100%;
    margin-top: 10px;

    button {
      margin-bottom: 20px;
    }
  }

}