body {
  background-color: #fafafa;
  font-family: "Mulish", sans-serif;
}

.error {
  color: red;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
