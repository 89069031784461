.detail-wrapper {
    .detail-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px,
    }

    .MuiTypography-root {
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        color: #000;
        margin-bottom: 0;
    }

 
    .reset {
        margin-top: 10px;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #8a8a8a;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }

    .reset {
        :last-child {
            margin-left: 5px;
            margin-top: 5px;
            color: #199ab0;

            &:hover {
                cursor: pointer;
            }
        }


    }

}

.pop-up-wapper {
    .pop-title {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 15px;
            color: #8a8a8a;
            margin-left: 5px;
        }
    }
}

.pass-form-wrapper {
    margin-top: 10px;
}

.button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    :last-child {
        margin-left: 10px;
    }
}

// .edit-profile-wrapper {
.upload-image {
    img {
        color: #199ab0;
        max-width: 150px;
    }
}

label {
    max-width: 200px;
    overflow: hidden;
}

.pop-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    span {
        font-size: 15px;
        color: #8a8a8a;
        margin-left: 5px;
        max-width: 200px;
        width: 100%;
    }
}

.avatar-container {
    position: relative;

    .avatar-label {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .avatar {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #199ab0;
        color: #ffffff;
        margin: 10px;

    }

    .cancel-btn {
        position: absolute;
        top: 0;
        height: 40px;
        left: 110px;
        border-radius: 50%;
        background-color: white;
        color: #199ab0;
        border: 2px solid #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }
}




// }

.image-crop-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .crop-btn {
        margin-top: 10px;
    }
}
.cropper-crop-box,
.cropper-view-box {
    border-radius: 50%;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}

.cropper-face {
    background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
    display: none !important;
}

.cropper-view-box {
    outline: inherit !important;
}