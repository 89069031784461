.table-wrapper {
    background: #f0f0f5;
    // padding: 10px;
    height: 100%;

    .heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .table-heading {
            font-size: 19px;
            color: #000;
            // font-family: Mulish, sans-serif, Helvetica Neue, sans-serif;
            font-weight: 400;
            line-height: 1.5;
        }

        .table-heading-btn {
            display: flex;
            align-items: center;
            // width: 100%;

            // justify-content: space-between;
            button {
                background: #199ab0;
                color: #ffffff;
                border: none;
                border-radius: 10px;
                width: 100%;
                padding: 15px 0px;
                margin-left: 15px;
                font-size: 16px;
                max-width: 150px;
                cursor: pointer;
                &:hover {
                    background: #199ab0;
                    color: #ffffff;
                }

                &:disabled {
                    background: #f2f2f2;
                    color: #000000;
                }
            }
        }
    }

    .table-content {
        background: #ffffff;
        padding: 20px 0px;
        border-radius: 10px;

    }
}