.login-container {
    display: flex;
    flex-direction: column;
    color: #fff;
    height: 100vh;
    background-size: cover;
    background-position: center;
    padding: 10rem 5rem 2rem;
    background-color: #1ca2b7;
padding-top: 300px;
    h3 {
        font-size: 3rem;
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    p {
        font-size: 18px;
        opacity: .8;
        margin-top: 0;
        margin-bottom: 1rem;
    }
}

.login-children {
    // display: flex;
    // justify-content: center;
    // flex-direction: column;
   .title{
    text-align: center;
    // padding-top: 10px;
   }
}

.action-btn,
.link-btn {
    background: #199ab0;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    width: 100%;
    padding: 15px 0px;

    &:hover {
        background: #199ab0;
        color: #ffffff;
    }
}

.link-btn {
    background: transparent;
    color: #000000;
    cursor: pointer;
    border: none;
    padding: 20px 0px;

    &:hover {
        background: #f2f2f2;
        color: #000000;
    }
}